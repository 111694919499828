.custom-text-color-1 {
    color: #3a3a3a !important;
}

.custom-text-color-2 {
    color: #3a3a3a !important;
}

.parallax-background {
     background-position-y: 165%;
 }